
@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 1400px) {
    .radar_pic{
        margin: 40px auto 0 !important;
    }
}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1170px) {
    .navbar-header {
        width: 322px;
    }

}





