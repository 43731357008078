$transition: all 0.2s ease-in-out;
$transition-half: all 0.5s ease-in-out;

$main: #4c84ff;
// $main: #ff0000; //красный проверочный

$background: #f6f8f9;

$btn-blue: mix(white, $main, 30%);

// $corporate_blue: #3271fe;
$corporate_blue: mix(black, $main, 3%);  //#4a80f7

// $light_blue: #80a8ff;
$light_blue: mix(white, $main, 35%);  //#8BAFFF

// $dark_blue: #285ACB;
$dark_blue: mix(black, $main, 25%);  //#3963BF

// $basic_grey: #3a424f;
$basic_grey: mix(black, $main, 80%);  //#0f1a33

// $muted_grey: #8b93a6;
$muted_grey: scale-color($main, $red: 35%, $blue: -35%);  //#8b84a6

$passive_grey: #bfc5d1;

// $dividing: #dee2ed;
$dividing: mix(white, $main, 85%);  //#E4EDFF

// $bgcontrols: #f5f6fa;
$bgcontrols: mix(white, $main, 95%);  //#F6F9FF

//COLORS STATUS CIRCLE
$bright_blue: #78d9da;
$purple: #aa8aff;
$orange: #ff8871;
$green: #94d674;

// $dark_green: #00aa00;
// $dark_green: scale-color($main, $green: 65%, $red: -80%);  //#33A600
$dark_green: scale-color($main, $green: 35%, $blue: -80%);  //#4CAF33

// $pink: #fc738d;
$pink: scale-color($main, $red: 95%, $blue: -38%);  //#F6849E

$brown: #a1968b;
$sky_blue: #9caed7;
$danger: #f00;


